.label {
    display: block;
    padding: 0 0 0 16px;
    font-weight: 700;
}

.body {
}

.input {
    display: block;
}

@media (min-width: 320px) and (max-width: 990px) {
    .body > div {
        padding: 0 !important;
    }
}
