.disabled {
    opacity: 0.5;
    pointer-events: none;
}

.transparent {
    opacity: 0.5;
}

.__react_component_tooltip {
    z-index: 10000 !important;
}
