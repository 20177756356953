.projectinfo {
    width: 50%;
}

.header {
}

.actions {
    display: flex;
}

.projectdates {
}

@media (min-width: 320px) and (max-width: 990px) {
    .projectinfo {
        width: calc(100vw - 20px);
        margin: 0 10px;
    }

    .projectdates {
        margin: 0 10px;
    }

    .header {
        flex-wrap: wrap;
    }

    .actions {
        margin: 10px auto;
        display: block;
        flex-wrap: wrap;
        width: 100%;
    }

    .actions > button {
        width: calc(100% - 10px);
    }
}
