.container {
    padding: 0 !important;
    width: 100% !important;
}

.table {
    width: 100%;
    position: relative;
}

.head {
    display: flex;
    font-weight: bold;
    color: #4e636d;
    border-bottom: 1px solid rgba(0, 135, 183, 1);
    line-height: 50px;
}

.project,
.date,
.actions {
    padding: 0 10px;
}

.project {
    color: rgba(0, 135, 183, 1);
    width: 40%;
}

.date {
    width: 18%;
}

.actions {
    width: 24%;
}

.actions {
    display: flex;
    max-width: 22%;
}

.body {
    position: relative;
}

.row {
    display: flex;
    border-bottom: solid 0.5px #eaebeb;
    line-height: 50px;
    color: #4e636d;
    position: relative;
}

.row:nth-child(odd) {
    background-color: #f8fafb;
}

.row:last-child {
    border: none;
}

.row:hover {
    background-color: rgba(0, 135, 183, 0.25);
}

.row .date strong {
    display: none;
    padding-right: 5px;
}

.name {
    width: 40%;
    padding: 0 10px;
}

.error {
    padding: 0 10px;
}

@media (min-width: 320px) and (max-width: 990px) {
    .head .date,
    .head .actions {
        display: none;
    }

    .row {
        display: block;
        line-height: 24px;
        padding-bottom: 20px;
    }

    .row .name {
        display: block;
        padding: 20px 0px 20px 20px;
        text-transform: uppercase;
        font-weight: bold;
    }

    .row .date {
        display: block;
        padding-left: 20px;
        width: calc(100% - 30px);
    }

    .row .date strong {
        display: inline-block;
    }

    .actions {
        position: absolute;
        top: 10px;
        right: 0;
    }
}
