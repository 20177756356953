.container {
    display: flex;
    margin: 30px auto 30px auto;
    flex-wrap: wrap;
}

.panel {
    margin: 30px !important;
    flex: 1;
    display: flex;
    flex-direction: column;
    place-content: space-between;
}

@media (min-width: 320px) and (max-width: 990px) {
    .container {
        flex-wrap: wrap;
    }

    .panel {
        width: 100%;
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
