.zipcodes {
    background-color: white;
    border: solid 1px rgba(0, 135, 183, 1);
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 58px;
    left: 0px;
    width: calc(76% + 36px);
    max-width: 480px;
    max-height: 259px;
    height: auto;
    overflow: auto;
    z-index: 10;
}

.zipcodes p {
    padding: 10px 20px;
    border-bottom: solid 1px rgba(0, 135, 183, 1);
}

.zipcodes p:hover {
    background-color: #ffd4af;
    cursor: pointer;
}

.zipcodes p:last-child {
    border-bottom: none;
}

.projectinfo {
    width: 50%;
}

.fullWidthQuestion > div {
    width: 100%;
}

.dimensions {
    display: flex;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.dimensions > div {
    width: 30%;
    min-width: 300px;
}

.results {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}

.block {
    flex: 50%;
}

.block > .result {
    min-width: 450px;
    margin: 5px;
}

.input {
    border: 1px solid #93aab2;
    border-radius: 3px;
    padding: 10px 20px;
    background-color: white;
    display: flex;
    margin: 5px;
}

.key {
    font-size: 18px;
    font-weight: 700;
    color: #4f636d;
    line-height: 42px;
}

.value {
    color: rgba(0, 135, 183, 1);
    font-size: 28px;
    font-weight: bold;
    margin-left: auto;
    place-self: center;
}

.norm {
    margin-left: 15px;
    margin-bottom: 20px;
}

.params {
    color: #4e636d;
    margin: -5px;
}

.params p {
    margin: 5px;
}

.params p strong {
    display: block;
}

.header {
}

.actions {
}

.importinfo {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.split {
    width: calc(50% - 80px) !important;
    height: fit-content;
}

.tableActions {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 20px;
}

.tableActions > * {
    margin: 5px 0;
}

.tableActions label {
    margin-right: 15px;
}

.tableContainer {
    overflow: scroll;
    max-height: 500px;
}

.table,
.resulttable {
    position: relative;
    overflow: auto;
    padding: 0 10px 10px 0;
    border-spacing: 0;
}

.table > thead,
.resulttable > thead {
    z-index: 10;
}

.table > thead > tr > th {
    position: -webkit-sticky;
    position: sticky;
    white-space: nowrap;
    top: 0;
    font-weight: bold;
    padding: 15px;
    min-width: 155px;
    text-align: center;
    border-left: 1px solid rgba(0, 135, 183, 1);
    border-top: 1px solid rgba(0, 135, 183, 1);
    border-bottom: 3px solid rgba(0, 135, 183, 1);
    background-color: white;
    z-index: 12;
}

.resulttable > thead > tr > th {
    position: -webkit-sticky;
    position: sticky;
    width: 15%;
    top: 0;
    font-weight: bold;
    padding: 15px;
    min-width: 155px;
    text-align: center;
    z-index: 12;
    background-color: #85bdbe;
    color: white;
    border-left: 1px solid white;
    border-bottom: 3px solid white;
}

.table > thead > tr > th:first-of-type {
    min-width: 30px;
    left: 0;
    background-color: white;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-bottom: 3px solid white;
    z-index: 22;
}

.resulttable > thead > tr > th:first-of-type,
.resulttable > thead > tr > th:nth-of-type(2) {
    border-left: 1px solid rgba(0, 135, 183, 1);
    border-top: 1px solid rgba(0, 135, 183, 1);
    border-bottom: 3px solid rgba(0, 135, 183, 1);
    background-color: white;
    color: black;
}

.table > thead > tr > th:nth-of-type(2) {
    border-top-left-radius: 3px;
}

.resulttable > thead > tr > th:first-of-type {
    border-top-left-radius: 3px;
    left: 0;
    z-index: 22;
}

.table > thead > tr > th:last-of-type {
    border-top-right-radius: 3px;
}

.resulttable > thead > tr > th:last-of-type {
    border-top-right-radius: 3px;
}

.table > thead > tr > th:last-of-type,
.table > tbody > tr > td:last-of-type {
    border-right: solid 1px rgba(0, 135, 183, 1);
}

.table > tbody > tr > * {
    padding: 15px;
    border-left: solid 1px rgba(0, 135, 183, 1);
    border-bottom: 1px solid #e7e7e7;
    align-content: center;
    text-align: center;
    white-space: nowrap;
}

.resulttable > tbody > tr > * {
    padding: 15px;
    border-left: 1px solid white;
    border-bottom: 1px solid white;
    background-color: rgba(0, 135, 183, 1);
    align-content: center;
    text-align: center;
    color: white;
}

.resulttable > tbody > tr > *:first-of-type {
    padding: 15px;
    border-left: solid 1px rgba(0, 135, 183, 1);
    border-bottom: 1px solid #e7e7e7;
    background-color: white;
    align-content: center;
    text-align: center;
    color: black;
}

.table > tbody > tr:last-of-type > th,
.table > tbody > tr:last-of-type > td {
    border-bottom: 1px solid rgba(0, 135, 183, 1);
}

.resulttable > tbody > tr:last-of-type > th,
.resulttable > tbody > tr:last-of-type > td {
    border-bottom: 1px solid rgba(0, 135, 183, 1);
}

.table > tbody > tr > th {
    height: 18px;
    display: flex;
    min-width: 30px;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    background-color: white;
    border-top: 1px solid white;
    border-left: 1px solid white;
    border-bottom: 1px solid #e7e7e7;
    padding: 15px 10px 15px 20px;
}

.resulttable > tbody > tr > th {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    font-weight: normal;
}

@media (min-width: 320px) and (max-width: 990px) {
    .projectinfo {
        width: calc(100vw - 20px);
        margin: 0 10px;
    }

    .header {
        flex-wrap: wrap;
    }

    .actions {
        flex-wrap: wrap;
    }

    .split {
        width: calc(100% - 40px) !important;
    }
}
