.zipcodes {
    background-color: white;
    border: solid 1px rgba(0, 135, 183, 1);
    border-radius: 3px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 58px;
    left: 0px;
    width: calc(76% + 36px);
    max-width: 480px;
    max-height: 259px;
    height: auto;
    overflow: auto;
    z-index: 10;
}

.zipcodes p {
    padding: 10px 20px;
    border-bottom: solid 1px rgba(0, 135, 183, 1);
}

.zipcodes p:hover {
    background-color: #ffd4af;
    cursor: pointer;
}

.zipcodes p:last-child {
    border-bottom: none;
}

.projectinfo {
    width: 50%;
}

.fullWidthQuestion > div {
    width: unset;
}

.results {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}

.block {
    flex: 50%;
}

.block > .result {
    min-width: 450px;
    margin: 5px;
}

.input {
    border: 1px solid #93aab2;
    border-radius: 3px;
    padding: 10px 20px;
    background-color: white;
    display: flex;
    margin: 5px;
}

.key {
    font-size: 18px;
    font-weight: 700;
    color: #4f636d;
    line-height: 42px;
}

.value {
    color: rgba(0, 135, 183, 1);
    font-size: 28px;
    font-weight: bold;
    margin-left: auto;
    place-self: center;
}

.norm {
    margin-left: 15px;
    margin-bottom: 20px;
}

.params {
    display: flex;
    color: #4e636d;
    justify-content: center;
    margin: -5px;
    flex-wrap: wrap;
}

.params p {
    flex: 20%;
    min-width: 150px;
    margin: 5px;
}

.params p strong {
    display: block;
}

.header {
}

.actions {
}

@media (min-width: 320px) and (max-width: 990px) {
    .projectinfo {
        width: calc(100vw - 20px);
        margin: 0 10px;
    }

    .header {
        flex-wrap: wrap;
    }

    .actions {
        flex-wrap: wrap;
    }
}
